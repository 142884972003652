@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* 파동 애니메이션 부분 */

.wave-outline {
    position: absolute;
    color: transparent;
    /* hide actual text */
    /* create outline effect */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    /* apply animation */
    animation-name: wave-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    top: 0;
    left: 0;
    transform-origin: center;
}

@keyframes wave-animation {
    0% {
        transform: scale(1);
        opacity: .3
    }
    90% {
        transform: scale(1.5);
        opacity: .6
    }
    100% {
        transform: scale(2);
        opacity: 0
    }
}

.main-background {
    min-height: 100vh;
    background-color: #001430;
    background-image: url('../../public/img/background.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
}


/* 메인화면 그라데이션 효과 */

.fade-in {
    animation-name: fade-in;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fade-in {
    to {
        opacity: 1;
    }
}

.main-menu-box-1 {
    width: 320px;
    height: 300px;
    background: linear-gradient(to right, #EC6842, transparent);
    border-radius: 10%;
}

.main-menu-box-2 {
    width: 320px;
    height: 300px;
    background: linear-gradient(to right, #1197AD, transparent);
    border-radius: 10%;
}

.main-menu-box-3 {
    width: 320px;
    height: 300px;
    background: linear-gradient(to right, #271708, transparent);
    border-radius: 10%;
}

.main-menu-box-4 {
    width: 320px;
    height: 300px;
    background: linear-gradient(to right, #3B8E64, transparent);
    border-radius: 10%;
}

.main-menu-button {
    width: 330px;
    height: 45px;
    background-color: #ffffff;
    border-radius: 10px;
}

.main-menu-sub-button-red {
    width: 80px;
    height: 32px;
    background-color: #FFE1E1;
    border-radius: 5px;
    color: #B20000;
}

.main-menu-sub-button-green {
    width: 80px;
    height: 35px;
    background-color: #E6FFE8;
    border-radius: 5px;
    color: #028E02;
}

.main-menu-sub-button-blue {
    width: 80px;
    height: 35px;
    background-color: #E3F1FF;
    border-radius: 5px;
    color: #0057AD;
}

.main-menu-sub-button-yellow {
    width: 80px;
    height: 35px;
    background-color: #FFFCE3;
    border-radius: 5px;
    color: #AA4D00;
}


/* 캐러잴 */

.firstarrow .slick-next:before {
    content: ''
}

.firstarrow .slick-prev:before {
    content: '';
}

.sliderMenu {
    border-radius: 20px;
}

.first {
    border-bottom: none !important;
}

.red {
    color: red;
}

.Backcolor_0 {
    background: linear-gradient(to right, #C1597B, transparent);
}

.Backcolor_1 {
    background: linear-gradient(to right, #EC6842, transparent);
}

.Backcolor_2 {
    background: linear-gradient(to right, #1197AD, transparent);
}

.Backcolor_3 {
    background: linear-gradient(to right, #271708, transparent);
}

.Backcolor_4 {
    background: linear-gradient(to right, #3B8E64, transparent);
}