.pageTitleLeft {
    padding-top: 6rem;
    padding-bottom: 3rem;
    font-size: 2.5rem;
    line-height: 1;
    margin-left: 2rem;
    font-family: 'NanumSquare_acEB';
}

.pageTitleCenter {
    padding-top: 6rem;
    padding-bottom: 3rem;
    font-size: 2.5rem;
    line-height: 1;
    text-align: center;
    font-family: 'NanumSquare_acEB';
}


/* 타임테이블 스타일 */

.TimeTable .timetableCirclesmall {
    border-radius: 100%;
    width: 10px;
    height: 10px;
}

.TimeTable .timetableCirclebigleft {
    border-radius: 100%;
    width: 15px;
    height: 15px;
    right: -33px;
    top: 10px;
}

.TimeTable .timetableCirclebigright {
    border-radius: 100%;
    width: 15px;
    height: 15px;
    left: -33px;
    top: 10px;
}

.TimeTable .timetableCircle_orange {
    background-color: #EF8E00;
}

.TimeTable .timetableCircle_green {
    background-color: #5EEFC6;
}

.TimeTable .timetableCircle_blue {
    background-color: #83B6FF;
}

.TimeTable .timetableCircle_white {
    background-color: white;
}

.TimeTableCircle {
    font-size: 8px;
    margin-right: 10px;
}

.whitebar {
    border-left: 3px solid white;
}

.whitebarright {
    border-right: 3px solid white;
}

.fade-in-top {
    -webkit-animation: fade-in-top 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}


/* 승부예측 경품 페이지 */

.predictionbtn button {
    background-color: #E5E7EA;
    color: black;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 13px;
}

.playstationbox {
    border: 1px solid white;
    border-radius: 15px;
}


/* 결졍된 승부 페이지 */

.URLBtn {
    text-align: center;
    padding: 30px;
}

.URLBtn div button {
    background-color: #1A3251;
    padding: 5px 10px;
    border-radius: 8px;
    margin: 10px;
    width: 200px;
}

.URLBtn div:first-child button {
    color: #69D8FF;
}

.URLBtn div:nth-child(2) button {
    color: #69D8FF;
}

.URLBtn div:nth-child(3) button {
    color: #00E095;
}

.URLBtn div:last-child button {
    color: #FF8D8D;
}


/* 굿즈 페이지 */

.goodsText {
    color: #00E2F9;
}

.goodsTitle {
    border-bottom: 1px solid white;
    width: 95%;
}

.towelcontent {
    font-size: 10px;
}


/* 시네마 */

.cinemacontent {
    font-size: 13px;
}

.cinemacontent1 {
    font-size: 10px;
}

.poster {
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: 20px;
}


/* 응원가 page */

.rightarrow {
    right: 40px;
    top: 32%;
}


/* chart */

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
    display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fade-in-left {
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 20:33:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */

@-webkit-keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.fade-in-right {
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 20:36:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */

@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}