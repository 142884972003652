@tailwind base;
@tailwind components;
@tailwind utilities;
@media (min-width: 375px) {
    .App,
    .Navbar {
        width: 375px;
    }
}

@media (max-width:500px) {
    .App,
    .Navbar {
        width: 100vw;
    }
}

#root {
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.App {
    min-height: 100vh;
    background-color: #001430;
    background-image: url('../public/img/background.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF_HailSnow';
    min-height: 100vh;
    margin: 0;
}

.last:last-child {
    min-height: 10vh;
}

.tracking-in-contract {
    -webkit-animation: tracking-in-contract 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-contract 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.heartbeat {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        letter-spacing: normal;
        opacity: 1;
    }
}

@keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        letter-spacing: normal;
        opacity: 1;
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-24 2:46:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}