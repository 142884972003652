.predictTeam {
    background-color: #E5E8EB;
    padding: 10px;
    color: black;
    border-radius: 10px;
}

.letspredict button {
    background-color: #E5E8EB;
    color: #34299B;
    padding: 2px 10px;
    border-radius: 10px;
}

.letspredictEnd button {
    background-color: #34299B;
    color: #E5E8EB;
    padding: 2px 10px;
    border-radius: 10px;
}

.swirl-in-fwd {
    -webkit-animation: swirl-in-fwd 0.6s ease-out both;
    animation: swirl-in-fwd 0.6s ease-out both;
}

@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}